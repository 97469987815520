import Component from '../lib/component.js';
import store from '../store/index.js';

import {cleanPrice, numberThousandSeparator} from '../../../utils';

export default class TotalParticipation extends Component {
    constructor() {
        super({
            store,
            element: document.querySelector('.participation-total-desktop')
        });
    }

    getAccessoriesTotalPriceCash = () => {
        const { accessories, paymentType } = store.state;

        if (accessories && paymentType === 'gotovina') {
            return accessories.reduce((accumulator, currentValue) => accumulator + cleanPrice(currentValue.priceCash), 0);
        }
        return 0;
    }

    getParticpationTotal = () => {
        const { participation, tariff, paymentType } = store.state;
        const { priceCash } = tariff;

        //console.log(priceCash, "MDBG priceCash")

        if (paymentType === 'gotovina') {
            return cleanPrice(priceCash) + this.getAccessoriesTotalPriceCash();
        }
        return cleanPrice(participation);
    }

    getParticipationTotalString =  () => {
        const participationTotal = this.getParticpationTotal();

        const { tariff, paymentType } = store.state;
        const { tariffOldPriceCash,priceCash } = tariff;
        let oldPriceCash = cleanPrice(tariffOldPriceCash);
        let priceCashClean = cleanPrice(priceCash);



        let xmassDiscountHtml = ``;
        // if(xmass2021) { //window.xmass2021 = xmass2021; a1_device_single_view.html
            if(oldPriceCash && paymentType === 'gotovina'  && priceCashClean !== oldPriceCash){
                //console.log (oldPriceCash, '<-------- oldPriceCash totalparticipation ')
                xmassDiscountHtml = `
                <div id="xmass-discount-total" class="m-device-card__price-discount">
                    <span class="a-01-text__primary--xs">${numberThousandSeparator(oldPriceCash)} RSD</span>
                </div>
            `;
            } else {
                xmassDiscountHtml = `
                <div id="xmass-discount-total" class="m-device-card__price-discount visibility_hidden">
                    <span class="a-01-text__primary--xs">RSD</span>
                </div>
            `;
            }
        // }

        // console.log(participationTotal, "MDBG participationTotal")

        return `
            ${xmassDiscountHtml}
            ${numberThousandSeparator(participationTotal)} RSD
        `;
    }


    /**
     * React to state changes and render the component's HTML
     *
     * @returns {void}
     */
    render() {

        this.element.innerHTML = this.getParticipationTotalString();

    }
}
