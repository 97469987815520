const tariffInitial = {  // eslint-disable-line no-unused-vars
    externalId: null,
    maxParticipationForActivation: null,
    minParticipationForActivation: null,
    monthlyFee: null,
    name: null,
    participation: null,
    price: null,
    priceCash: null,
    tariffCode: null,
    tariffName: null,
    tariffProperties: null,
};

export default {
    addItem(state, payload) {
        state.items.push(payload);
        
        return state;
    },
    clearItem(state, payload) {
        state.items.splice(payload.index, 1);
        return state;
    },
    setTestString(state, payload) {
        state.testString = payload;
        return state;
    },
    setTariff(state, payload) {
        const deviceType = payload.deviceType ? parseInt(payload.deviceType) : 0;
        state.tariff = payload;
        if (!state.tariff?.minParticipationForActivation) {
            if (deviceType === 2 || deviceType === 8) {
                state.tariff.minParticipationForActivation = 2400;
            } else {
                state.tariff.minParticipationForActivation = 2400;
            }
        }
        return state;
    },
    setPaymentType(state, payload) {
        if (payload === 'rate' || payload === 'gotovina') {
            state.paymentType = payload;
        } else {
            state.paymentType = 'rate';
        }
        return state;
    },
    setInstalments(state, payload) {
        const payloadInt = parseInt(payload);
        if (payloadInt > 1 && payloadInt <= 24) {
            state.instalments = payloadInt;
        }
        return state;
    },
    setParticipation(state, payload) {
        state.participation = payload;
        return state;
    },
    setInsurance(state, payload) {
        state.insurance = payload.insurance;
        return state;
    },
    appendAccessory(state, payload) {
        state.accessories = [...state.accessories, payload];
        return state;
    },
    removeAccessory(state, payload) {
        state.accessories = state.accessories.filter(acc => {
            return acc.code !== payload.code;
        })
        return state;
    },
    setIsTvOrTable(state, payload) {
        state.isTvOrTablet = payload.isTvOrTablet;
        return state;
    },
    setIsAccessory(state, payload) {
        state.isAccessory = payload.isAccessory
        return state;
    },
    setIsBusiness(state, payload) {
        state.isBusiness = payload.isBusiness
        return state;
    },
    setIsBusinessAndTablet(state, payload) {
        state.isBusinessAndTablet = payload.isBusinessAndTablet
        return state;
    },
    setTariffCommunicationPromoList(state, payload) {
        state.tariffCommunicationPromoList = payload.tariffCommunicationPromoList
        return state;
    },
};
