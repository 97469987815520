/**
 * Naziv tarife i mesečna pretplata. Max 1 kom.
 * {
 *      tariff: 'SPO_SRPP3L',
 *      tariffMontlyFee: 271,
 *      tariffMontlyFeeWithoutDevice: 212, // Samo ukoliko se tarifa doda a u korpi nije telefon
 * }
 * @type {Object}
 * @private
 */
class Tariff {
    constructor() {
        this._tariffCode = null;
        this._price = null;
        this._priceCash = null;
        this._oldPrice = null;
        this._oldPriceCash = null;
        this._rate24 = null;
        this._participation = null;
        this._participationOld = null;

        this._samsungneo = null;
        this._externalId = null;
        this._tariffName = null;
        this._name = null;
        this._monthlyFee = null;
        this._monthlyFeeWithDiscount = null;
        this._tariffMontlyFeeWithoutDevice = null;
        this._monthlySummary = null;
        this._tariffProperties = [];
        this._minParticipationForActivation = null;
        this._maxParticipationForActivation = null;
        this._tariffCollection = null;
        this._oldMonthlyDevicePrice = null;
        this._tariffOldPrice = null;
        this._tariffOldPriceCash = null;
        this._tariffMonthlyPriceOld = null;
        this._tariffMonthlyDevicePriceOld = null;
        this._shortDescription = null;
        this._shortDescriptionWithDevice = null;
        this._shortDescriptionWithoutDevice = null;
    }

    getAll = () => ({
        tariffCode: this._tariffCode,
        price: this._price,
        priceCash: this._priceCash,
        oldPrice:  this._oldPrice,
        oldPriceCash: this._oldPriceCash,
        externalId: this._externalId,
        tariffName: this._tariffName,
        name: this._name,
        monthlyFee: this._monthlyFee,
        tariffMontlyFeeWithoutDevice: this._tariffMontlyFeeWithoutDevice,
        tariffProperties: this._tariffProperties,
        participation: this._participation,
        participationOld: this._participationOld,
        minParticipationForActivation: this._minParticipationForActivation,
        maxParticipationForActivation: this._maxParticipationForActivation,
        tariffCollection: this._tariffCollection,
        oldMonthlyDevicePrice: this._oldMonthlyDevicePrice,
        tariffOldPrice: this._tariffOldPrice,
        tariffOldPriceCash: this._tariffOldPriceCash,
        tariffMonthlyPriceOld: this._tariffMonthlyPriceOld,
        tariffMonthlyDevicePriceOld: this._tariffMonthlyDevicePriceOld,
        shortDescription: this._shortDescription,
        shortDescriptionWithDevice: this._shortDescriptionWithDevice,
        shortDescriptionWithoutDevice: this._shortDescriptionWithoutDevice,
    });
    getProp = (prop) => this[`_${prop}`];

    setAll = (tariffCode, price, priceCash, externalId, tariffName, name, monthlyFee, monthlyFeeWithoutDevice, tariffProperties, participation = 0, minParticipationForActivation = 0, maxParticipationForActivation = 0, tariffCollection, oldMonthlyDevicePrice, tariffOldPrice, tariffOldPriceCash, tariffMonthlyPriceOld, tariffMonthlyDevicePriceOld,shortDescription, shortDescriptionWithDevice, shortDescriptionWithoutDevice) => {
        this._tariffCode = tariffCode;
        this._price = price;
        this._priceCash = priceCash;
        this._externalId = externalId;
        this._tariffName = tariffName;
        this._name = name;
        this._monthlyFee = monthlyFee;
        this._tariffMontlyFeeWithoutDevice = monthlyFeeWithoutDevice;
        this._tariffProperties = tariffProperties;
        this._participation = participation;
        this._minParticipationForActivation = minParticipationForActivation;
        this._maxParticipationForActivation = maxParticipationForActivation;
        this._tariffCollection = tariffCollection;
        this._oldMonthlyDevicePrice = oldMonthlyDevicePrice;
        this._tariffOldPrice = tariffOldPrice;
        this._tariffOldPriceCash = tariffOldPriceCash;
        this._tariffMonthlyPriceOld = tariffMonthlyPriceOld;
        this._tariffMonthlyDevicePriceOld = tariffMonthlyDevicePriceOld;
        this._shortDescription = shortDescription;
        this._shortDescriptionWithDevice = shortDescriptionWithDevice;
        this._shortDescriptionWithoutDevice = shortDescriptionWithoutDevice;

    };

    /**
     * Refactored version of setTariff. It is very hard to use setTariff with 15+ params, so we will use object in future.
     * For now, we will leave old setTariff method until we are sure that noting is using it any more.
     * @param tariffCode
     * @param price
     * @param priceCash
     * @param externalId
     * @param tariffName
     * @param name
     * @param monthlyFee
     * @param monthlyFeeWithoutDevice
     * @param tariffProperties
     * @param participation
     * @param minParticipationForActivation
     * @param maxParticipationForActivation
     * @param tariffCollection
     * @param oldMonthlyDevicePrice
     * @param tariffOldPrice
     * @param tariffOldPriceCash
     * @param shortDescription
     * @param shortDescriptionWithDevice
     * @param shortDescriptionWithoutDevice
     */
    setAllNew = ({
         tariffCode,
         price,
         priceCash,
         externalId,
         tariffName,
         name,
         monthlyFee,
         monthlyFeeWithoutDevice,
         tariffProperties,
         participation = 0,
         participationOld = 0,
         minParticipationForActivation = 0,
         maxParticipationForActivation = 0,
         tariffCollection,
         oldMonthlyDevicePrice,
         tariffOldPrice,
         tariffOldPriceCash,
         tariffMonthlyPriceOld,
         tariffMonthlyDevicePriceOld,
         shortDescription,
         shortDescriptionWithDevice,
         shortDescriptionWithoutDevice,
                 }) => {
        this._tariffCode = tariffCode;
        this._price = price;
        this._priceCash = priceCash;
        this._externalId = externalId;
        this._tariffName = tariffName;
        this._name = name;
        this._monthlyFee = monthlyFee;
        this._tariffMontlyFeeWithoutDevice = monthlyFeeWithoutDevice;
        this._tariffProperties = tariffProperties;
        this._participation = participation;
        this._participationOld = participationOld;
        this._minParticipationForActivation = minParticipationForActivation;
        this._maxParticipationForActivation = maxParticipationForActivation;
        this._tariffCollection = tariffCollection;
        this._oldMonthlyDevicePrice = oldMonthlyDevicePrice;
        this._tariffOldPrice = tariffOldPrice;
        this._tariffOldPriceCash = tariffOldPriceCash;
        this._tariffMonthlyPriceOld = tariffMonthlyPriceOld;
        this._tariffMonthlyDevicePriceOld = tariffMonthlyDevicePriceOld;
        this._shortDescription = shortDescription;
        this._shortDescriptionWithDevice = shortDescriptionWithDevice;
        this._shortDescriptionWithoutDevice = shortDescriptionWithoutDevice;
    };
    setProp =( prop, value ) => {
        this[`_${prop}`] = value;
    };
    isEmpty = () => !this._externalId &&
        !this._tariffCode &&
        !this._price &&
        !this._priceCash &&
        !this._tariffName &&
        !this._name &&
        !this._monthlyFee &&
        !this._tariffMontlyFeeWithoutDevice;

    appendToProperties = (item) => {
        if (!this._tariffProperties) {
            this._tariffProperties = [];
        }
        this._tariffProperties.append(item);
    }

    setProperties = (properties) => {
        this._tariffProperties = properties;
    }


    // get tariffCode() {
    //     return this._tariffCode;
    // }
    //
    // set tariffCode(value) {
    //     this._tariffCode = value;
    // }
    // get monthlyFee() {
    //     return this._monthlyFee;
    // }
    //
    // set monthlyFee(value) {
    //     this._monthlyFee = value;
    // }
    //
    // get tariffMontlyFeeWithoutDevice() {
    //     return this._tariffMontlyFeeWithoutDevice;
    // }
    //
    // set tariffMontlyFeeWithoutDevice(value) {
    //     this._tariffMontlyFeeWithoutDevice = value;
    // }

}

export default Tariff;
window.Tariff = Tariff;
