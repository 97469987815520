import Insurance from './Insurance';
import Tariff from './Tariff';
import Device from './Device';
import Accessories from './Accessories';
import TariffAddon from './TariffAddon';
import PDPVirtualCart from './PDPVirtualCart';
import PDPHandler from './PDPHandler';
import {cleanPrice, numberThousandSeparator} from '../../utils';


function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export const VOUCHERS_LIMIT = VOUCHERS_LIMIT_CONST; // eslint-disable-line no-undef
export const VOUCHERS_LENGTH_LIMIT = VOUCHERS_LENGTH_LIMIT_CONST; // eslint-disable-line no-undef
export const VOUCHERS_MAXLENGTH_LIMIT = VOUCHERS_MAXLENGTH_LIMIT_CONST; // eslint-disable-line no-undef
/**
 * VirtualCart
 */
class VirtualCart {
    constructor() {
        if (VirtualCart._instance) {
            return VirtualCart._instance;
        }

        VirtualCart._instance = this;

        /**
         * process moze biti: Novi broj, prelazak sa prepaid na postpaid, obnova ugovora, dodavanje uređaja, prijenos
         * broja iz druge mreže…
         process -> [ renewal | dig | cc2pop | postpaid | add_hw | add_3rd_party_hw | mnp | p2p | add_acc ]
         accessories[]
         installments_number
         installments_amount
         installments_upfront
         tariff_bpos[]
         * @type {String}
         * @private
         */
        this._process = null;
        this._tariff = new Tariff();
        this._device = new Device();
        this._device2 = new Device();
        this._tariffAddon = new TariffAddon();
        this._tariffAddons = [];
        /**
         * @type {int}
         * @private
         */
        this._instalments = 24;
        /**
         * @type {Accessories[]}
         * @private
         */
        this._accessories = [];
        this._gadgets = [];
        this._tariffIncrease = null;
        this._insurance = new Insurance();
        this._paymentType = 'rate';
        this._participation = 0;
        this._bezUgovorneObaveze = false;
        this._binding = 24;

        const storageTypes = {
            1: 'cookie',
            2: 'localStorage',
        };

        this._storageType = storageTypes[2];
        this.webshopLink = LC['webshop_url'] + '/sr/configure-item/add-item'; // eslint-disable-line no-undef
        this._isEBill = true;
        this._isESim = false;
        this._isPaperBill = !this._isEBill;
        this._mnpLink = null;
        this._paperBillFee = 0;
        this._shouldIgnorePaperBillFee = false;

        this._preselctedHW = null;
        this._isPreselctedHWRequired = false;

        this._vouchers = [];
        this.tariffSplit = {};
        this.init();
        return this;
    }

    getPreselectedHW = () => this._preselctedHW;
    setPreselectedHW = val => {
        this._preselctedHW = val;
    };

    removePreselectedHW = () => {
        this._preselctedHW = null;
    }
    getIsPreselectedHWRequired = () => this._isPreselctedHWRequired;
    setIsPreselectedHWRequired = () => {
        this._isPreselctedHWRequired = true;
    };
    disableIsPreselectedHWRequired = () => {
        this._isPreselctedHWRequired = false;
    };

    setMnpLink = (mnpLink) => {
        this._mnpLink = mnpLink;
    }
    getMnpLink = () => this._mnpLink;

    setIsEBill = (val) => {
        this._isEBill = val;
        this._isPaperBill = !this._isEBill;
        if (this._isPaperBill) { // !this._shouldIgnorePaperBillFee
            this._paperBillFee = 50;
        } else {
            this._paperBillFee = 0;
        }
        this.setAll();
    }

    getIsEBill = () => this._isEBill;

    getPaperBillFee = () => this._paperBillFee;

    getIsPaperBill = () => this._isPaperBill;

    setBinding = (binding) => this._binding = binding;

    getBinding = () => this._binding;

    setBezUgovorneObaveze = val => {
        this._bezUgovorneObaveze = val;
    }

    setShouldIgnorePaperBillFee = (val) => {
        this._shouldIgnorePaperBillFee = val;
    }

    setWebshopLink = (webshopLink) => this.webshopLink = webshopLink;

    resetAccessories = () => {
        this._accessories = [];
    }
    resetGadgets = () => {
        this._gadgets = [];
    }
    resetTariffAddons = () => {
        this._tariffAddons = [];
    }
    resetTariffAddonsExceptBoosters = () => {
        this._tariffAddons = this._tariffAddons.filter(addon => !addon?.is_booster);
    }

    calculateTotal = () => {
        const {participation, accessories, gadgets, tariff} = this.getAll();
        const {price} = tariff;

        const devicePrice = cleanPrice(price);

        const devicePriceMonthly = (devicePrice - participation);

        let participationTotal = participation;
        let monthlyTotal = 0;
        monthlyTotal += devicePriceMonthly;
        if (accessories && accessories.length) {
            const totalParticipationAcc = accessories.reduce((accumulator, currentValue) => {
                return parseInt(accumulator) + parseInt(currentValue.accessoryParticipation);
            }, 0);
            const monthlyTotalAcc = accessories.reduce((accumulator, currentValue) => {
                return parseInt(accumulator) + (parseInt(cleanPrice(currentValue.accessoryPrice) - cleanPrice(currentValue.accessoryParticipation)));
            }, 0);
            monthlyTotal += monthlyTotalAcc;
            participationTotal += totalParticipationAcc
        }
        if (gadgets && gadgets.length) {
            const totalParticipationGadget = gadgets.reduce((accumulator, currentValue) => {
                return parseInt(accumulator) + parseInt(currentValue.accessoryParticipation);
            }, 0);
            const monthlyTotalGadget = gadgets.reduce((accumulator, currentValue) => {
                return parseInt(accumulator) + (parseInt(cleanPrice(currentValue.accessoryPrice) - cleanPrice(currentValue.accessoryParticipation)));
            }, 0);
            monthlyTotal += monthlyTotalGadget;
            participationTotal += totalParticipationGadget
        }

        return {totalParticipation: participationTotal, totalMonthly: monthlyTotal};


    }
    goToShop = (isFromMNPModal = false) => {
        const {device, tariff, accessories, gadgets, tariffAddons, paymentType, insurance} = virtualCart.getAll(); // eslint-disable-line no-undef
        const {deviceCode, deviceMmid, deviceInstalments, deviceType} = device;
        const {tariffCode, name: tariffName} = tariff;
        const {isSelected: isSelectedInsurance} = insurance;
        let webshopLink = 'https://a1.rs/webshopfe/details' + '?';
        console.log(webshopLink, 'webshopLink');

        const isTariffEmpty = virtualCart.getTariff().isEmpty(); // eslint-disable-line no-undef
        const isDeviceEmpty = virtualCart.getDevice().isEmpty(); // eslint-disable-line no-undef
        const areAccessoriesEmpty = virtualCart.accessories.length === 0; // eslint-disable-line no-undef
        const areAGadgetsEmpty = virtualCart.gadgets.length === 0; // eslint-disable-line no-undef
        // const areTariffAddonsEmpty = virtualCart.tariffAddons.length === 0; // eslint-disable-line no-undef
        // const paymentType = virtualCart.getPaymentType(); // eslint-disable-line no-undef
        const mnpLink = virtualCart.getMnpLink();

        //if (tariffCode === 'STANDALONE' || tariffCode === 'SPO_PREPAIDR') {
        if (tariffCode === 'STANDALONE' || tariffCode === 'PREPAIDR') {
            // shouldOpenModal = false;
            if (tariffCode === 'STANDALONE') {
                virtualCart.process = 'STANDALONE';
            //} else if (tariffCode === 'SPO_PREPAIDR') {
            } else if (tariffCode === 'PREPAIDR') {
                virtualCart.process = 'prpreg';
            }
            virtualCart.setAll();
        }
        if (this.process === 'mnp' && !isFromMNPModal) {
            $('#mnp-modal').addClass('is-on');
            // window.location.href = `${mnpLink}?ref=cart`;
            return;
        }


        const isOnlyAcc = (isTariffEmpty && isDeviceEmpty && (!areAccessoriesEmpty || !areAGadgetsEmpty));
        if (isOnlyAcc) {
            if (paymentType === 'gotovina') {
                virtualCart.process = 'acc'; // eslint-disable-line no-undef
            } else if (paymentType === 'rate') {
                virtualCart.process = 'add_acc'; // eslint-disable-line no-undef
            }
        }
        // if (deviceType === '3') {
        //     virtualCart.process = 'preselected-hw'; // eslint-disable-line no-undef
        //
        // }


        //LC['webshop_url']
        const wsurl = LC['webshop_url']; // eslint-disable-line no-undef


        const isJustTariff = !isTariffEmpty && isDeviceEmpty;
        // const isTariffAndAcc = !isTariffEmpty && isDeviceEmpty && (!areAccessoriesEmpty);

        if (isJustTariff || deviceType === '3') {
            // webshopLink = wsurl + '/sr/configure-sim/add-sim?';
        }

        const {totalParticipation, totalMonthly} = this.calculateTotal();

        let startWithAmpersand = false;
        if (deviceCode && deviceType !== '3') {
            webshopLink += `dev_type=${deviceCode}`;
            startWithAmpersand = true;
            if (isSelectedInsurance) {
                webshopLink += '&insurance=1';
            }
        }

        if (deviceMmid) {
            webshopLink += `&mmid=${deviceMmid}`;
        }

        if (tariffCode) {
            let isThisFirst = false;
            if (!deviceCode) {
                isThisFirst = true
            }

            webshopLink += `${isThisFirst ? '' : '&'}tariff=${tariffCode}`;
            //if (tariffCode !== 'STANDALONE' && tariffCode !== 'SPO_PREPAIDR') {
            if (tariffCode !== 'STANDALONE' && tariffCode !== 'PREPAIDR') {
                if (this._bezUgovorneObaveze && isDeviceEmpty) {
                    webshopLink += '&binding=0';
                } else {
                    webshopLink += `&binding=${this._binding}`;
                }
            }

            const processList = virtualCart.process ? virtualCart.process.split(';') : '';
            if (processList && processList.includes('mnp')) {
                webshopLink = webshopLink.replace('&binding=24', '&binding=0');
            }

            if (isJustTariff && !this?._preselctedHW?.isPreselctedHWRequired) {
                if (tariffName === 'Online tarifa') {
                    webshopLink += '&sim=DATA';
                } else {
                    if(tariffName === "PonesiNet"
                        || tariffName === 'KućniNet M'
                        || tariffName === 'KućniNet L'
                        || tariffName === 'KućniNet XL'
                        || tariffName === "KućniNet XXL") {
                        //console.log("fms, mbb")
                    } else {
                        webshopLink += '&sim=VOICE';
                    }
                }

            }
            if (deviceType === '3' || this?._preselctedHW?.isPreselctedHWRequired) {
                webshopLink += '&sim=DATA';
            }
        }

        if (isOnlyAcc && paymentType === 'rate') {
            let ampersand = startWithAmpersand ? '&' : '';
            webshopLink += `${ampersand}tariff=ACCINSTALL`;
        } else if (isOnlyAcc && paymentType === 'gotovina') {
            webshopLink += '&tariff=STANDALONE';
        }


        if (accessories) {
            accessories.forEach(accessory => {
                const {accessoryCode} = accessory;
                webshopLink += `&accessories[]=${accessoryCode}`;
            });
        }
        if (gadgets) {
            gadgets.forEach(accessory => {
                const {accessoryCode} = accessory;
                webshopLink += `&accessories[]=${accessoryCode}`;
            });
        }

        var isMNP = virtualCart.process === 'mnp'; // eslint-disable-line

        if (paymentType === 'rate') {
            if (!isTariffEmpty && isDeviceEmpty && areAccessoriesEmpty && areAGadgetsEmpty) {
                // webshopLink = 'https://a1.rs/webshop-test/sr/configure-sim/add-sim?';
            } else {
                if (deviceInstalments && !isMNP) {
                    webshopLink += `&installments_number=${deviceInstalments}`;
                }
            }

            if (totalParticipation && !isMNP) {
                webshopLink += `&installments_upfront=${totalParticipation}`;
            }

            if (totalMonthly && !isMNP) {
                let totalMonthlyToSend = Math.round(totalMonthly / deviceInstalments);
                if (parseFloat(this.tariff.price) === 1 && this.getPaymentType() === 'rate' && (this.accessories.length > 0 || this.gadgets.length > 0)) {
                    totalMonthlyToSend = totalMonthlyToSend + 1;
                }
                webshopLink += `&installments_amount=${totalMonthlyToSend}`
            }
            if (isMNP) {
                webshopLink += '&installments=1';
            }
        } else if (paymentType === 'gotovina') {
            webshopLink += '&installments=0';
            if (deviceCode) {
                if (deviceType === '3') {
                    webshopLink += splitByCommaAndGenerateUrlParams(LC['BPO_DISCOUNT_HARDWARE_MODEM_CASH'], 'additional_bpos');
                } else {
                    webshopLink += splitByCommaAndGenerateUrlParams(LC['BPO_DISCOUNT_HARDWARE_CASH'], 'additional_bpos');
                }
                if (!areAccessoriesEmpty || !areAGadgetsEmpty) {
                    webshopLink += splitByCommaAndGenerateUrlParams(LC['BPO_DISCOUNT_ACCESSORY_CASH'], 'additional_bpos');
                }
            } else {
                if (!areAccessoriesEmpty || !areAGadgetsEmpty) {
                    webshopLink += splitByCommaAndGenerateUrlParams(LC['BPO_DISCOUNT_ACCESSORY_CASH'], 'additional_bpos');
                }
            }
        }
        if (tariffAddons) {
            tariffAddons.forEach(addon => {
                let shouldIgnoreAddon = false;
                if (addon?.is_booster && addon?.bpo_added_with_tariff && addon?.bpo_added_with_tariff !== tariffCode) {
                    shouldIgnoreAddon = true;
                }
                if(tariffCode === 'BPO_NEO24_P') {
                    shouldIgnoreAddon = false;
                    // remove insurance for netflix which is old protect
                    /*if (addon?.bpo === LC['PROTECT_ADDON_BPOS']) { // protect addon
                        webshopLink += '&insurance=1';
                    }*/
                    if (addon?.bpo ===LC['TRAVEL_ADDON_BPOS']) {
                        webshopLink += '&trinsurance=1';
                    }
                }

                let shouldUseAddon = true;
                if (addon?.is_booster && addon?.bpo_valid_for_SPO !== tariffCode) {
                    shouldUseAddon = false;
                }
                if (shouldUseAddon) {
                    const splitedBpos = addon?.bpo?.split(';');
                    if (splitedBpos && !shouldIgnoreAddon) {
                        splitedBpos.forEach(bpo => {
                            webshopLink += `&tariff_bpos[]=${bpo}`
                        });
                    }
                }

            });
        }

        if (tariffCode === 'BPO_NEOPLATINUM_P') { // platinum tariff
            webshopLink += '&insurance=1';
        }

        if (this._isEBill) {
            if ((!areAccessoriesEmpty || !areAGadgetsEmpty) && isDeviceEmpty) {

            } else {
                webshopLink += '&e-bill=1';
            }
        }

        // ako je 3rd_party
        if (deviceType === '7') {
            webshopLink += '&3rd_party=1';
        }

        if (this._isESim) {
            webshopLink += '&e-sim=1';
        }

        if (this._vouchers.length > 0) {
            this._vouchers.forEach(voucher => {
                webshopLink += `&vouchers[]=${voucher}`;
            });
        }

        if(this.tariffSplit && this.tariffSplit.tariffSplitMonths && this.tariffSplit.tariffSplitBefore) {
            webshopLink += `&tariffSplitMonths=${this.tariffSplit.tariffSplitMonths}&tariffSplitBefore=${this.tariffSplit.tariffSplitBefore}`;
        }

        if (!virtualCart.process) { // eslint-disable-line no-undef
            const guidedSellingDrawer = document.querySelector('#drawer-guided-selling');
            if (guidedSellingDrawer) {
                gs.handleVirtualCartOptions(); // eslint-disable-line no-undef
                guidedSellingDrawer.classList.add('is-on');
            }
        } else {
            if (isOnlyAcc && paymentType === 'gotovina') {  // eslint-disable-line no-empty
            } else {
                const splicedProcesses = virtualCart.process.split(';');  // eslint-disable-line no-undef
                splicedProcesses.forEach(singleProcess => {
                    webshopLink += `&process[]=${singleProcess}`
                });
            }

            if (this._mnpLink) {
                const mnpWarningModal = document.querySelector('#mnp-warning-modal');
                if (mnpWarningModal) {
                    mnpWarningModal.classList.add('is-on');
                }
                // window.location.href = this._mnpLink;
            } else {

                if (this._isPreselctedHWRequired && this._preselctedHW?.code) {
                    webshopLink += `&preselected-hw=${this._preselctedHW?.code}`
                }

                setCheckoutStep1Datalayer();

                const pricesForAll = this.getPricesForEveryItemInCart();


                if (pricesForAll) {
                    // webshopLink += '&preset=' + b64EncodeUnicode(JSON.stringify(pricesForAll));
                }
                console.log(webshopLink, 'webshopLink');
                if (webshopLink.includes('?&')) {
                    webshopLink = webshopLink.replace('?&', '?')
                }
                let cartHashForSet = null;
                const { device: mainDevice } = virtualCart.getAll(); // eslint-disable-line no-undef
                const { deviceCode } = mainDevice;
                $.ajax({
                    type: 'POST',
                    url: '/api/save-cart',
                    data: {
                        cartEncoded: virtualCart.encodeCart(), // eslint-disable-line no-undef
                        deviceCode: deviceCode,
                    },
                    dataType: 'json',
                    // timeout: 13000
                })
                    .done(function (saveCartRespData)  {
                        let cartDataForSet = virtualCart.getAll();
                        cartDataForSet.originCart = {
                            'hash': saveCartRespData.hash,
                            'link': saveCartRespData.link
                        };
                        $.ajax({
                            type: 'POST',
                            url: '/api/set-cart',
                            data: {
                                preset_json: JSON.stringify(pricesForAll),
                                cart_json: JSON.stringify(cartDataForSet)
                            },
                            dataType: 'json',
                            // timeout: 13000
                        })
                            .done(function (data) {
                                if (data?.cart_id) {
                                    webshopLink += '&cart_id=' + data.cart_id;
                                }

                                console.log(webshopLink, 'webshopLink');
                                window.location.href = webshopLink;
                            })
                            .fail(function (e) {
                                console.log('fail', e);
                                // $('#main-msg').text(LC['SYSTEM_ERROR']);
                            })
                    })
            }
            //https://a1.rs/webshop-test/sr/configure-item/add-item?&installments_number=24&e-bill=1&process[]=add_acc&preset=eyJkZXZpY2UiOm51bGwsImFjY2Vzc29yaWVzQW5kR2FkZ2V0cyI6bnVsbH0=

            // https://www.vipmobile.rs/webshop/webshop-test/sr/configure-item/add-item?&tariff=STANDALONE&accessories[]=AD_19124&accessories[]=AD_18280&installments=1

        }
    }

    /**
     *
     * @returns {{device: null, accessoriesAndGadgets: null}}
     */
    getPricesForEveryItemInCart = () => {
        const allPrices = {
            device: null,
            accessoriesAndGadgets: null,
            insurance: null,
            tariff: null,
            tariffAddons: null,
            preselectedHw: null,
        };
        allPrices.device = this.getPriceForDevice();
        allPrices.preselectedHw = this.getPriceForPreselectedHw();
        allPrices.accessoriesAndGadgets = this.getPricesForAccessoriesAndGadgets();
        allPrices.insurance = this.getInsuranceDataFromCart();
        allPrices.tariff = this.getTariffDataFromCart();
        allPrices.tariffAddons = this.getAddonsPrices();
        return allPrices;
    }

    getPriceForPreselectedHw = () => {
        const { _preselctedHW } = this;

        if (_preselctedHW?.code) {
            const { price, name, manufacturer } = _preselctedHW;

            return {
                price: cleanPrice(price) === 1 ? 0 : cleanPrice(price),
                priceCash: cleanPrice(price) === 1 ? 1 : null,
                oldPrice: null,
                oldPriceCash: null,
                participation: null,
                name: `${manufacturer} ${name}`
            }
        }

        return null;
    }

    /**
     * @returns {{price: number, priceCash: number}|null}
     */
    getPriceForDevice = () => {
        const { tariffCode } = this.tariff;

        if (tariffCode && this.device?.deviceCode) { // we have tariff code even if it is standalone - standalone is also tariff :)
            const { price, priceCash, oldPrice, oldPriceCash } = this.tariff;

            return {
                price: cleanPrice(price),
                priceCash: cleanPrice(priceCash),
                oldPrice: oldPrice ? cleanPrice(oldPrice) : null,
                oldPriceCash: oldPriceCash ? cleanPrice(oldPriceCash) : null,
                participation: cleanPrice(this._participation),
            }
        }
        return null;
    }

    getPricesForAccessoriesAndGadgets = () => {
        const accessoryPrices = this.getAccessoryPricesList();
        const gadgetsPrices = this.getGadgetsPricesList();

        if (!accessoryPrices && !gadgetsPrices) {
            return null;
        }
        if (!accessoryPrices && gadgetsPrices) {
            return gadgetsPrices;
        }
        if (accessoryPrices && !gadgetsPrices) {
            return accessoryPrices;
        }
        return { ...accessoryPrices, ...gadgetsPrices };
    }

    getGadgetsPricesList = () => {
        const { gadgets } = this;

        if (!gadgets || gadgets?.length === 0) {
            return null;
        }
        return gadgets.reduce((accumulator, currentValue) => {
            return {
                ...accumulator,
                [currentValue.accessoryCode]: {
                    price: cleanPrice(currentValue.accessoryPrice),
                    oldPrice: null,
                    participation: cleanPrice(currentValue.accessoryParticipation),
                    priceCash: cleanPrice(currentValue.accessoryPrice) * 0.9,
                }
            }
        }, {});
    }

    getTariffDataFromCart = () => {
        const { tariff, device } = this;
        if (tariff?.tariffCode && tariff?.tariffCode !== 'STANDALONE') {
            const { monthlyFee, tariffCode, name, tariffMontlyFeeWithoutDevice, oldMonthlyDevicePrice } = tariff;
            return {
                priceMonthly: cleanPrice(monthlyFee),
                priceMonthlyWithoutDevice: device?.deviceCode ? null : cleanPrice(tariffMontlyFeeWithoutDevice),
                oldPriceMonthly: cleanPrice(oldMonthlyDevicePrice),
                code: tariffCode,
                name,
                properties: this.tariff.tariffProperties
            }
        }
        return null;
    }

    getInsuranceDataFromCart = () => {
        const { insurance } = this;

        if (!insurance || !insurance?.isSelected) {
            return null;
        }

        const { insuranceType, insurancePrice, insurancePromotionActive, insuranceParticipation} = insurance;

        return {
            "category": parseInt(insuranceType),
            "price": cleanPrice(insurancePrice),
            "damageParticipation": cleanPrice(insuranceParticipation),
            "isPromoActive": !!insurancePromotionActive,
        }
    }

    getAccessoryPricesList = () => {
        const { accessories } = this;

        if (!accessories || accessories?.length === 0) {
            return null;
        }
        return accessories.reduce((accumulator, currentValue) => {
            return {
                ...accumulator,
                [currentValue.accessoryCode]: {
                    price: cleanPrice(currentValue.accessoryPrice),
                    oldPrice: null,
                    participation: cleanPrice(currentValue.accessoryParticipation),
                    priceCash: cleanPrice(currentValue.accessoryPrice) * 0.9,
                }
            }
        }, {});
    }

    getAddonsPrices = () => {
        const { tariffAddons, tariff } = this;

        if (!tariffAddons || tariffAddons?.length === 0) {
            return null;
        }
        return tariffAddons
          .filter(addon => addon?.bpo_valid_for_SPO === tariff?.tariffCode)
          .map(addon => {
              const isPromoActive = !!addon?.bpo_promo;
            return {
                is_promo_active: isPromoActive,
                promo_price: isPromoActive ? cleanPrice(addon?.promo_price) : null,
                name: addon?.title,
                price: isPromoActive ? cleanPrice(addon?.promo_price) : cleanPrice(addon?.price),
                bpo: addon?.bpo,
                bpo_promo: addon?.bpo_promo,
                old_price: isPromoActive ? cleanPrice(addon?.price) : null
                // is_booster: addon?.is_booster1
            }
        })
    }



    init = () => {

    };
    test = () => {
        this.setAllDummyValues();
        this.setAll();
        this.setAllFromStorage();
    };

    setAllDummyValues() {
        this.process = 'process1';
        this.setTariff('SPO1KDJASD', 500, 5000, '12312', 'TARIFA', 'TARIFA 1 10', 1500, 1200);

        this.setDevice('ADWEA', 'Huawei', 'P20', '#FAAFAA', 16235, 20000, 12, 10000, 2000, 'https://www.vipmobile.rs/upload/devices_thumbs/APPA221825/APPA221825_image001_300_450.png?revision=20_06_12');

        this.setDevice2('POISA', 'Huawei', 'P30', '#FAAFAA', 16235, 25000, 12, 12000, 1820, 'https://www.vipmobile.rs/upload/devices_thumbs/APPA221825/APPA221825_image001_300_450.png?revision=20_06_12');

        // this.setTariffAddon('BLA', 'Roaming 1GB', 500);

        this.instalments = 12;

        //(accId, code, type, commercialName, manufacturerName, name, price)
        this.appendToAccessories(123, 'dDASAD1', '123', 'comercialName', 'tip', 'ime', 'price', 'https://www.vipmobile.rs/upload/devices_thumbs/AD_17294/AD_17294_image001_200_300.png');
        this.appendToAccessories(1234, 'dgASAD1', '122', 'comercialName1', 'tip1', 'ime1', 'price1', 'https://www.vipmobile.rs/upload/devices_thumbs/AD_17294/AD_17294_image001_200_300.png');


        this.tariffIncrease = 'ne znm sta ide ovde';

        this.setInsurance('BPO123', 'Osiguranje', 500, 5000);
    }

    getAll = () => ({
        process: this.process,
        tariff: this.tariff,
        device: this.device,
        device2: this.device2,
        tariffAddon: this.tariffAddon,
        tariffAddons: this.tariffAddons,
        accessories: this.accessories,
        gadgets: this.gadgets,
        tariffIncrease: this.tariffIncrease,
        insurance: this.insurance,
        paymentType: this._paymentType,
        participation: this._participation,
        instalments: this._instalments,
        webshopLink: this.webshopLink,
        bezUgovorneObaveze: this._bezUgovorneObaveze,
        binding: this._binding,
        mnpLink: this._mnpLink,
        eSim: this._isESim,
        vouchers: this._vouchers,
        paperBillFee: this._paperBillFee,
        isPaperBill: this._isPaperBill,
        preselctedHW: this._preselctedHW,
        isPreselctedHWRequired: this._isPreselctedHWRequired,
    });

    clearCart = () => {
        setDataLayerRemoveFromCart(virtualCart);
        this._process = null;
        this._tariff = new Tariff();
        this._device = new Device();
        this._device2 = new Device();
        this._tariffAddon = new TariffAddon();
        this._tariffAddons = [];
        this._instalments = null;
        this._accessories = [];
        this._gadgets = [];
        this._tariffIncrease = null;
        this._insurance = new Insurance();
        this._paymentType = 'rate';
        this._participation = 0;
        this._webshopLink = LC['webshop_url'] + '/webshop/sr/configure-item/add-item'; // eslint-disable-line no-undef
        this._bezUgovorneObaveze = false;
        this._mnpLink = null;
        this._isESim = false;
        this._vouchers = [];
        this._preselctedHW = null;
        this._isPreselctedHWRequired = false;
        this.setAll();
    };

    isCartEmpty = () => !this._process &&
        this._tariff.isEmpty() &&
        this._device.isEmpty() &&
        this._device2.isEmpty() &&
        this._tariffAddons.length === 0 &&
        this._accessories.length === 0 &&
        this._gadgets.length === 0 &&
        !this._tariffIncrease;

    _setAllInStorage = () => {
        const stringCartItems = this.encodeCart();
        //console.log('_setAllInStorage encodeCart')
        if (this._storageType === 'localStorage') {
            if (stringCartItems && typeof stringCartItems === 'string') {
                //console.log('_setAllInStorage virtualCart in localStorage')
                localStorage.setItem('virtualCart', stringCartItems);
            }
        } else if (this._storageType === 'cookie') {
            setCookie('virtualCart', stringCartItems, 30); // eslint-disable-line no-undef
        }
    };

    setAllFromStorage = () => {
        let cartFromStorage = null;
        if (this._storageType === 'localStorage') {
            if (!window?.localStorage) {
                return;
            }
            cartFromStorage = localStorage.getItem('virtualCart');
            //console.log('cartFromStorage setAllFromStorage')
        } else if (this._storageType === 'cookie') {
            cartFromStorage = anvilGetCookie('virtualCart'); // eslint-disable-line no-undef
        } else {
            throw new Error('Storage Type Not Set');
        }

        if (cartFromStorage) {
            const dataFromStorage = this.decodeCart(cartFromStorage);
            //console.log(dataFromStorage,'dataFromStorage setAllFromStorage')
            if (!dataFromStorage) {
                return;
            }
            const {
                accessories,
                device,
                device2,
                insurance,
                process,
                tariff,
                tariffAddons,
                tariffIncrease,
                gadgets,
                paymentType,
                participation,
                instalments,
                webshopLink,
                bezUgovorneObaveze,
                binding,
                mnpLink,
                eSim,
                vouchers,
                paperBillFee,
                isPaperBill,
                preselctedHW,
                isPreselctedHWRequired
            } = dataFromStorage;
            //console.log(paymentType,'paymentType dataFromStorage setAllFromStorage')
            if (instalments) {
                this._instalments = instalments;
            }

            if (accessories && Array.isArray(accessories)) {
                accessories.forEach((accessory) => {
                    this.appendToAccessoriesN({
                        accId: accessory?.accessoryId,
                        code: accessory?.accessoryCode,
                        type: accessory?.accessoryType,
                        commercialName: accessory?.accessoryComercialName,
                        manufacturerName: accessory?.accessoryManufacturerName,
                        name: accessory?.accessoryName,
                        price: accessory?.accessoryPrice,
                        image: accessory?.accessoryImage,
                        participation: accessory?.accessoryParticipation,
                        priceCash: accessory?.priceCash,
                    });
                });
            }
            if (gadgets && Array.isArray(gadgets)) {
                gadgets.forEach((accessory) => {
                    this.appendToGadget(accessory?.accessoryId, accessory?.accessoryCode, accessory?.accessoryType, accessory?.accessoryComercialName, accessory?.accessoryManufacturerName, accessory?.accessoryName, accessory?.accessoryPrice, accessory?.accessoryImage, accessory?.accessoryParticipation);
                });
            }

            if (device) {
                this.setDevice(device?.deviceType, device?.deviceCode, device?.deviceManufacturer, device?.deviceName, device?.deviceColor, device?.deviceMmid, device?.devicePriceCash, this._instalments, device?.deviceParticipation, device?.deviceMonthlyFee, device?.deviceImage);
            }
            if (device2) {
                this.setDevice2(device2?.deviceType, device2?.deviceCode, device2?.deviceManufacturer, device2?.deviceName, device2?.deviceColor, device2?.deviceMmid, device2?.devicePriceCash, device2?.deviceInstalments, device2?.deviceParticipation, device2?.deviceMonthlyFee, device?.deviceImage);
            }
            if (insurance) {
                this.setInsurance1({
                    type: insurance?.insuranceType,
                    name: insurance?.insuranceName,
                    price: insurance?.insurancePrice,
                    participation: insurance?.insuranceParticipation,
                    isSelected: insurance?.isSelected,
                    insurancePromotionActive: insurance?.insurancePromotionActive,
                });
                // this.setInsurance(insurance?.insuranceType, insurance?.insuranceName, insurance?.insurancePrice, insurance?.insuranceParticipation, insurance?.isSelected);
            }
            if (process) {
                this.process = process;
            }
            if (tariff) {
                this.setTariffNew({
                    tariffCode: tariff?.tariffCode,
                    price: tariff?.price,
                    priceCash: tariff?.priceCash,
                    externalId: tariff?.externalId,
                    tariffName: tariff?.tariffName,
                    name: tariff?.name,
                    monthlyFee: tariff?.monthlyFee,
                    monthlyFeeWithoutDevice: tariff?.tariffMontlyFeeWithoutDevice,
                    properties: tariff?.tariffProperties,
                    participation: tariff?.participation,
                    minParticipationForActivation: tariff?.minParticipationForActivation,
                    maxParticipationForActivation: tariff?.maxParticipationForActivation,
                    oldMonthlyDevicePrice:  tariff?.oldMonthlyDevicePrice,
                    tariffCollection: tariff?.tariffCollection,
                    tariffOldPrice: tariff?.tariffOldPrice,
                    tariffOldPriceCash: tariff?.tariffOldPriceCash,
                    tariffMonthlyPriceOld: tariff?.tariffMonthlyPriceOld,
                    tariffMonthlyDevicePriceOld: tariff?.tariffMonthlyDevicePriceOld,
                    shortDescription: tariff?.shortDescription,
                    shortDescriptionWithDevice: tariff?.shortDescriptionWithDevice,
                    shortDescriptionWithoutDevice: tariff?.shortDescriptionWithoutDevice,
                })
                // this.setTariff(tariff?.tariffCode, tariff?.price, tariff?.priceCash, tariff?.externalId, tariff?.tariffName, tariff?.name, tariff?.monthlyFee, tariff?.tariffMontlyFeeWithoutDevice, tariff?.tariffProperties, tariff?.participation, tariff?.minParticipationForActivation, tariff?.maxParticipationForActivation, tariff?.oldMonthlyDevicePrice);
            }
            if (paymentType) {
                //console.log(' OVO JE U SET ALL FROM STORAGE', paymentType);
                this.setPaymentType(paymentType);
                this._paymentType = paymentType;
            }

            if (tariffIncrease) {
                this.tariffIncrease = tariffIncrease;
            }

            if (participation) {
                this._participation = participation;
            }
            if (webshopLink) {
                this.webshopLink = webshopLink;
            }
            if (bezUgovorneObaveze) {
                this._bezUgovorneObaveze = bezUgovorneObaveze;
            }
            if (binding) {
                this._binding = binding;
            }
            if (mnpLink) {
                this._mnpLink = mnpLink;
            }
            if (eSim) {
                this._isESim = eSim;
            }
            if (vouchers) {
                this._vouchers = vouchers;
            }
            if (paperBillFee) {
                this._paperBillFee = paperBillFee;
            }
            if (isPaperBill) {
                this._isPaperBill = isPaperBill;
            }
            if (preselctedHW) {
                this._preselctedHW = preselctedHW;
            }
            if (isPreselctedHWRequired) {
                this._isPreselctedHWRequired = isPreselctedHWRequired;
            }
            if (tariffAddons && Array.isArray(tariffAddons)) {
                tariffAddons.forEach(addon => {
                    this.addTariffAddon(addon, true);
                });
                // this.setTariffAddon(tariffAddon?.addonType, tariffAddon?.addonName, tariffAddon?.addonPrice);
            }

        }
    };


    setAll = () => {
        this._setAllInStorage();
        //console.log('Vc setAll')
        //console.trace();
    };


    get instance() {
        return this._instance;
    }

    set instance(value) {
        this._instance = value;
    }

    get process() {
        return this._process;
    }

    set process(value) {
        this._process = value;
    }

    get tariff() {
        return this._tariff.getAll();
    }

    getTariff = () => this._tariff;

    setTariff = (tariffCode, price, priceCash, externalId, tariffname, name, monthlyFee, monthlyFeeWithoutDevice, properties, participation, min, max, oldMonthlyDevicePrice, tariffOldPrice = null, tariffOldPriceCash = null, shortDescription) => {
        this._tariff.setAll(tariffCode, price, priceCash, externalId, tariffname, name, monthlyFee, monthlyFeeWithoutDevice, properties, participation, min, max, null, oldMonthlyDevicePrice, tariffOldPrice, tariffOldPriceCash, shortDescription);
    };

    /**
     * Refactored version of setTariff. It is very hard to use setTariff with 15+ params, so we will use object in future.
     * For now, we will leave old setTariff method until we are sure that noting is using it any more.
     * @param tariffCode
     * @param price
     * @param priceCash
     * @param externalId
     * @param tariffname
     * @param name
     * @param monthlyFee
     * @param monthlyFeeWithoutDevice
     * @param properties
     * @param participation
     * @param min
     * @param max
     * @param oldMonthlyDevicePrice
     * @param tariffOldPrice
     * @param tariffOldPriceCash
     * @param shortDescription
     * @param shortDescriptionWithDevice
     * @param shortDescriptionWithoutDevice
     */
    setTariffNew = ({
        tariffCode,
        price,
        priceCash,
        externalId,
        tariffName,
        name,
        monthlyFee,
        monthlyFeeWithoutDevice,
        properties,
        participation,
        minParticipationForActivation,
        maxParticipationForActivation,
        oldMonthlyDevicePrice,
        tariffCollection,
        tariffOldPrice,
        tariffOldPriceCash,
        tariffMonthlyPriceOld,
        tariffMonthlyDevicePriceOld,
        shortDescription,
        shortDescriptionWithDevice,
        shortDescriptionWithoutDevice,
    }) => {
        this._tariff.setAllNew({
            tariffCode,
            price,
            priceCash,
            externalId,
            tariffName,
            name,
            monthlyFee,
            monthlyFeeWithoutDevice,
            tariffProperties: properties,
            minParticipationForActivation,
            maxParticipationForActivation,
            oldMonthlyDevicePrice,
            tariffCollection,
            tariffOldPrice,
            tariffOldPriceCash,
            tariffMonthlyPriceOld,
            tariffMonthlyDevicePriceOld,
            participation,
            shortDescription,
            shortDescriptionWithDevice,
            shortDescriptionWithoutDevice,
        });
        // this._tariff.setAll(tariffCode, price, priceCash, externalId, tariffname, name, monthlyFee, monthlyFeeWithoutDevice, properties, participation, min, max, null, oldMonthlyDevicePrice);
    };

    get device() {
        return this._device.getAll();
    }

    getDevice = () => this._device;

    setDevice = (type, code, manufacturer, name, color, mmid, price, instalments, participation, montlyFee, image) => {
        this._device.setAll(type, code, manufacturer, name, color, mmid, price, instalments, participation, montlyFee, image);
    };

    get device2() {
        return this._device2.getAll();
    }

    setDevice2 = (type, code, manufacturer, name, color, mmid, price, instalments, participation, montlyFee, image) => {
        this._device2.setAll(type, code, manufacturer, name, color, mmid, price, instalments, participation, montlyFee, image);
    };

    get tariffAddon() {
        return this._tariffAddon.getAll();
    }

    get tariffAddons() {
        return this._tariffAddons.map(addon => addon.getAll());
    }

    addTariffAddon = (addon,isCalledFromStorage = false) => {
        if (this._tariffAddons.length < 10 && addon) {
            const tariffAddonObj = new TariffAddon(addon);
            const doesExists = this.getTariffAddons().filter(currentAddon => addon.id === currentAddon.id);
            if (!tariffAddonObj.isEmpty() && doesExists.length === 0) {
                this._tariffAddons.push(tariffAddonObj);
                if (isCalledFromStorage) {
                    this.setAll();
                }
            }
        }
    }
    getTariffAddons = () => {
        return this._tariffAddons.map(addon => addon.getAll());
    }
    // setTariffAddon = (type, name, price) => {
    //     if (this.tariff) {
    //         this._tariffAddon.setAll(type, name, price);
    //     } else {
    //         console.warn('Tariff addon can\'t be set because Tariff is not set');
    //     }
    // };

    get instalments() {
        return this._instalments;
    }

    set instalments(value) {
        this._instalments = value;
    }

    get accessories() {
        return this._accessories.map(acc => acc.getAll());
    }

    get gadgets() {
        return this._gadgets.map(acc => acc.getAll());
    }
    appendToGadgetsN = ({
                                accId,
                                code,
                                type,
                                commercialName,
                                manufacturerName,
                                name,
                                price,
                                image,
                                participation,
                                priceCash
                            }) => {
        if (this._gadgets.length < 10) {
            const exists = this._gadgets.filter((acc => acc.accessoryId === accId));
            if (exists.length > 0) {
                /**
                 * this will happen if only participation is changed
                 */
                if (exists[0].accessoryParticipation !== participation) {
                    this._gadgets = this._gadgets.filter((acc => acc.accessoryId !== accId));
                } else {
                    return;
                }

            }
            // const gadget = new Accessories()
            const accessory = new Accessories();
            // accessory.setAll(accId, code, type, commercialName, manufacturerName, name, price, image, participation);
            accessory.setAllN({
                accId,
                code,
                type,
                commercialName,
                manufacturerName,
                name,
                price,
                priceCash,
                image,
                participation,
                isGadget: true
            });
            this._gadgets.push(accessory);
        }
    };
    appendToAccessoriesN = ({
        accId,
        code,
        type,
        commercialName,
        manufacturerName,
        name,
        price,
        image,
        participation,
        priceCash
    }) => {
        if (this._accessories.length < 10) {
            const exists = this._accessories.filter((acc => acc.accessoryId === accId));
            if (exists.length > 0) {
                /**
                 * this will happen if only participation is changed
                 */
                if (exists[0].accessoryParticipation !== participation) {
                    this._accessories = this._accessories.filter((acc => acc.accessoryId !== accId));
                } else {
                    return;
                }

            }
            const accessory = new Accessories();
            // accessory.setAll(accId, code, type, commercialName, manufacturerName, name, price, image, participation);
            accessory.setAllN({
                accId,
                code,
                type,
                commercialName,
                manufacturerName,
                name,
                price,
                priceCash,
                image,
                participation,
            });
            this._accessories.push(accessory);
        }
    };
    appendToAccessories = (accId, code, type, commercialName, manufacturerName, name, price, image, participation) => {
        if (this._accessories.length < 10) {
            const exists = this._accessories.filter((acc => acc.accessoryId === accId));
            if (exists.length > 0) {
                /**
                 * this will happen if only participation is changed
                 */
                if (exists[0].accessoryParticipation !== participation) {
                    this._accessories = this._accessories.filter((acc => acc.accessoryId !== accId));
                } else {
                    return;
                }

            }
            const accessory = new Accessories();
            accessory.setAll(accId, code, type, commercialName, manufacturerName, name, price, image, participation);
            this._accessories.push(accessory);
        }
    };

    appendToGadget = (accId, code, type, commercialName, manufacturerName, name, price, image, participation) => {
        if (this._gadgets.length < 10) {
            const exists = this._gadgets.filter((acc => acc.accessoryId === accId));
            if (exists.length > 0) {
                return;
            }
            const accessory = new Accessories(true);
            accessory.setAll(accId, code, type, commercialName, manufacturerName, name, price, image, participation, true);
            this._gadgets.push(accessory);
        } else {
            console.log('gadget can`t be added. array is full (10 items)');
        }
    };

    get tariffIncrease() {
        return this._tariffIncrease;
    }

    set tariffIncrease(value) {
        this._tariffIncrease = value;
    }

    get insurance() {
        return this._insurance.getAll();
    }

    toggleInsurance = () => {
        this._insurance.toggleSelected();
        this.setAll();
    }

    isSelectedInsurance = () => this._insurance.isSelected();

    setParticipation = participation => {
        this._participation = participation;
    }
    getParticipation = () => this._participation;

    setInsurance1 = ({type, name, price, participation, isSelected = false, insurancePromotionActive = false}) => {
        if (this.device) {
            if (isSelected) {
                this._insurance.setSelected(true);
            }
            this._insurance.setAll(type, name, price, participation, insurancePromotionActive);
        } else {
            console.warn('Insurance can\'t be set because Device is not set');
        }
    }
    setInsurance = (type, name, price, participation, isSelected = false) => {
        if (this.device) {
            if (isSelected) {
                this._insurance.setSelected(true);
            }
            this._insurance.setAll(type, name, price, participation);
        } else {
            console.warn('Insurance can\'t be set because Device is not set');
        }
    };
    encodeCart = () => b64EncodeUnicode(JSON.stringify(this.getAll()));

    decodeCart = (encodedCart) => JSON.parse(b64DecodeUnicode(encodedCart));

    setPaymentType = (value) => {
        //console.log('setPaymentType', value);
        this._paymentType = value;
    };

    getPaymentType = () => this._paymentType;

    removeAccessory = code => {
        this._accessories = this._accessories.filter(acc => acc.accessoryCode !== code);
        this.setAll();
    };

    removeTariffAddon = code => {
        this._tariffAddons = this._tariffAddons.filter(addon => {
            const {bpo} = addon.getAll();
            return bpo !== code;
        });
        this.setAll();
    }
    removeAllAddons = () => {
        this._tariffAddons = [];
        this.setAll();
    }
    removeAllBoosterAddons = () => {
        this._tariffAddons = this._tariffAddons.filter(tariffAddon => !tariffAddon.state.is_booster)
        this.setAll();
    }

    setSplitTariff = (obj) => {
        this.tariffSplit = obj;
    }

    getSplitTariff = () => {
        return this.tariffSplit;
    }

    removeGadget = code => {
        this._gadgets = this._gadgets.filter(acc => acc.accessoryCode !== code);
        this.setAll();
    };

    recalculateAccAndTariffTotal = () => {
        const {accessories, gadgets} = virtualCart.getAll();  // eslint-disable-line no-undef

        const atp = accessories.reduce((accumulator, currentValue) => {
            return parseInt(accumulator) + (parseInt(cleanPrice(currentValue.accessoryPrice)) - parseInt(cleanPrice(currentValue.accessoryParticipation)));
        }, 0);


        const gtp = gadgets.reduce((accumulator, currentValue) => {
            return parseInt(accumulator) + (parseInt(cleanPrice(currentValue.accessoryPrice)) - parseInt(cleanPrice(currentValue.accessoryParticipation)));
        }, 0);

        return atp + gtp;
    }

    getTotal = (state) => {
        const {
            deviceMonthlyFee,
            accesoryMonthlyFee,
            deviceParticipation,
            tariffMonthlyFee,
            insuranceMonthlyFee,
            accessoryParticipation,
        } = state;
        let totalMonthlyPrice = 0;
        let totalParticipation = 0;
        const {isSelected, insurancePromotionActive} = this.insurance;

        let deviceParticipationClean = deviceParticipation;
        if (typeof deviceParticipation === 'string') {
            deviceParticipationClean = cleanPrice(deviceParticipation);
        }

        if (deviceMonthlyFee) {
            totalMonthlyPrice += cleanPrice(deviceMonthlyFee);
        }
        if (this.recalculateAccAndTariffTotal()) {
            if (this._paymentType === 'rate') {
                totalMonthlyPrice += cleanPrice(this.recalculateAccAndTariffTotal()) / this.instalments;
            } else {
                totalMonthlyPrice += 0;
            }
        }
        if (accessoryParticipation) {
            totalParticipation += cleanPrice(accessoryParticipation);
        }
        if (tariffMonthlyFee) {
            totalMonthlyPrice += cleanPrice(tariffMonthlyFee);
        }
        if (insuranceMonthlyFee && isSelected && !insurancePromotionActive) {
            totalMonthlyPrice += cleanPrice(insuranceMonthlyFee);
        }
        if (deviceParticipation) {
            totalParticipation += cleanPrice(deviceParticipationClean);
        }

        totalMonthlyPrice += this._paperBillFee;

        if (this.process === 'mnp' || this.process === 'postpaid') {
            totalParticipation += 1;
        }

        return {
            totalParticipation,
            totalMonthlyPrice,
        };
    }

    getTariffBoosters = () => this._tariffAddons.filter(tariffAddon => tariffAddon.state.is_booster)

    getFirstBooster = () => {
        const boosters = this.getTariffBoosters();
        if (boosters && boosters.length > 0) {
            return boosters[0]
        }
        return null
    }

    setSimCardType = () => {
        const processListForEsim = ['postpaid', 'mnp'];
        if (processListForEsim.includes(this.process)) {
            $('.sim-card-type-section-wrapper').removeClass('display_none');
        } else {
            $('.sim-card-type-section-wrapper').addClass('display_none');
        }

        if (virtualCart.getESim()) {
            $('#radio-e-sim').click();
        }
    }

    setESim = (val) => {
        this._isESim = !!val;
        this.setAll();
    }

    getESim = () => this._isESim;

    getVouchers = () => this._vouchers;

    removeVoucher = voucherToRemove => {
        this._vouchers = this._vouchers.filter(voucher => voucher !== voucherToRemove);
        this.setAll();
    }

    addVoucher = voucher => {
        if (!voucher) {
            return [false, 'missing argument (voucher code)'];
        }

        if (typeof voucher !== 'string') {
            return [false, 'voucher code must be string'];
        }

        if (voucher.length < VOUCHERS_LENGTH_LIMIT) {
            return [false, `voucher code must be at least ${VOUCHERS_LENGTH_LIMIT} characters long`];
        }

        if (this.doesVoucherExists(voucher)) {
            return [false, 'duplicate'];
        }

        if (this._vouchers.length >= VOUCHERS_LIMIT) {
            return [false, 'vouchers_limit'];
        }

        this._vouchers = [...this._vouchers, voucher];
        this.setAll();
        return [true, 'success'];
    }


    /**
     * Calculate price for device
     * @returns {[number,number]}
     */
    getDevicePrices = () => {
        //console.log("$DBG1: Ne treba da se javi ako je dodat acc!");
        const { device, tariff, paymentType, participation, accessories } = this.getAll();
        const { deviceImage, deviceName, deviceInstalments, deviceMonthlyFee, deviceParticipation } = device;
        const { price, priceCash } = tariff;

        //console.log(participation, "participation before upfront")
        //console.log(deviceParticipation, "deviceParticipation before upfront")

        let monthly = 0;
        let upfront = 0;

        if (paymentType === 'gotovina') {
            monthly = 0;
            //console.log(priceCash, 'priceCashpriceCashpriceCash');
            upfront = priceCash;
        } else {
            if (deviceMonthlyFee) {
                const devicePrice = cleanPrice(price);
                let monthlyDevicePriceTotal = devicePrice;
                if (cleanPrice(deviceParticipation)) {
                    monthlyDevicePriceTotal = devicePrice - (cleanPrice(deviceParticipation));
                }

                if (participation || participation === 0) {
                    monthlyDevicePriceTotal = devicePrice - participation;
                }

                monthly  =  Math.floor(monthlyDevicePriceTotal / deviceInstalments);

                //console.log("$DBG2", device.deviceCode);
                if(device.deviceCode && accessories.length > 0) {
                    //console.log("$DBG3 Ne diraj upfront ako postoji device i dodat je acc")
                } else {
                    //console.log('')
                    if (cleanPrice(deviceParticipation)) {
                        upfront = cleanPrice(deviceParticipation);
                    } else {
                        upfront = 0;
                    }

                    if (participation) {
                        upfront = participation;
                    }
                }
            }
        }

        //console.log(monthly, "monthly precart")
        //console.log(upfront, "upfront precart")

        return [monthly, upfront];
    }

    getAccessoryPrices = (accessoryCode, isGadget = false) => {
        if (!accessoryCode) {
            return null;
        }

        const accessory = isGadget ? this.getGadget(accessoryCode) : this.getAccessory(accessoryCode);
        const { paymentType, device } = virtualCart.getAll(); // eslint-disable-line no-undef
        const { deviceInstalments } = device;
        const installments = deviceInstalments || 24;
        const { accessoryPrice, priceCash, accessoryParticipation: currentAccessoryParticipation = 0  } = accessory;
        let monthly = 0;
        let upfront = currentAccessoryParticipation;

        let priceCashFinal = priceCash;
        if (!priceCashFinal) {
            priceCashFinal = cleanPrice(accessoryPrice) * 0.9;
        }

        if (paymentType === 'rate') {

            const cleanFullPrice = cleanPrice(accessoryPrice);
            if (cleanFullPrice === 1) {
                monthly = 1;
            } else {
                const cleanPriceVal = cleanFullPrice - currentAccessoryParticipation;
                monthly = Math.round(cleanPriceVal / installments);
            }

        } else if (paymentType === 'gotovina') {
            upfront = Math.round(priceCashFinal)
        } else {
            //console.warning('payment type is invalid');
        }

        //console.log(monthly, "monthly precart")
        //console.log(upfront, "upfront precart")

        return [monthly, upfront];
    }
    getAccessory = accessoryCode => {
        if (!accessoryCode || !this._accessories || this._accessories.length === 0) {
            return null;
        }

        const accessoryArr = this._accessories.filter(acc => acc.accessoryCode === accessoryCode);
        if (!accessoryArr || accessoryArr.length === 0) {
            return null;
        }
        return accessoryArr[0];
    }
    getGadget = accessoryCode => {
        if (!accessoryCode || !this._gadgets || this._gadgets.length === 0) {
            return null;
        }

        const accessoryArr = this._gadgets.filter(acc => acc.accessoryCode === accessoryCode);
        if (!accessoryArr || accessoryArr.length === 0) {
            return null;
        }
        return accessoryArr[0];
    }

    isAccOnly = () => {
        const isTariffEmpty = this.getTariff().isEmpty();
        const isDeviceEmpty = this.getDevice().isEmpty();
        const areAccessoriesEmpty = this.accessories.length === 0;
        const areAGadgetsEmpty = this.gadgets.length === 0;
        return isTariffEmpty && isDeviceEmpty && (!areAccessoriesEmpty || !areAGadgetsEmpty)
    }

    doesVoucherExists = voucher => !!this._vouchers.filter(v => v === voucher).length;

    getMmidsForAllProducts = () => {
        const { device, accessories, gadgets } = this.getAll();
        const { deviceMmid, deviceCode, deviceType } = device;
        const accessoriesMmids = accessories.map(acc => acc.accessoryCode);
        const gadgetsMmids = gadgets.map(acc => acc.accessoryCode);
        let deviceMmidToSend = deviceMmid;
        if (deviceMmid && !deviceMmid?.startsWith('SPO_')) {
            if (deviceType === '7') {
                deviceMmidToSend = deviceCode;
            } else {
                deviceMmidToSend = `SPO_${deviceMmid}`;
            }
        }
        return [deviceMmidToSend, ...accessoriesMmids, ...gadgetsMmids];
    }
}



window.VirtualCart = VirtualCart;
window.PDPVirtualCart = PDPVirtualCart;
window.PDPHandler = PDPHandler;

export default VirtualCart;
